import router from '@/router/index.js';
import axios from 'axios';

let timer = null;

export default {
  resetUser(context) {
    context.commit('resetUser');
    clearTimeout(timer);
    timer = null;
  },
  setUser(context, payload) {
    context.commit('setUser', payload);

    const expiresIn = +payload.userTokenExpiration - new Date().getTime();

    // console.log('expiresIn');
    // console.log(expiresIn);

    // console.log('userTokenExpiration');
    // console.log(payload.userTokenExpiration);

    // Logout user if token expires in less than 2 minutes
    // if (expiresIn < 120000) {
    //   context.dispatch('autoLogout');
    // } else {
    //   // Logout user after token expires
    //   timer = setTimeout(() => {
    //     context.dispatch('autoLogout');
    //   }, expiresIn);
    // }

    // Logout user after token expires
    timer = setTimeout(() => {
      context.dispatch('autoLogout');
    }, expiresIn);
  },
  // Updates autologout timer each times the user navigates throughout the app
  resetAutologoutTimer(context) {
    clearTimeout(timer);
    timer = null;

    const tokenExpiration = 172800 * 1000; // 2 days
    const tokenExpirationDate = new Date().getTime() + tokenExpiration;

    context.dispatch('setUserTokenExpiration', tokenExpirationDate);

    // Logout user after token expires
    timer = setTimeout(() => {
      context.dispatch('autoLogout');
    }, tokenExpiration);
  },
  setUserLoggedIn(context, payload) {
    context.commit('setUserLoggedIn', payload.value);
  },
  setUserName(context, payload) {
    context.commit('setUserName', payload.value);
  },
  setPassword(context, payload) {
    context.commit('setPassword', payload.value);
  },
  setUserDetails(context, payload) {
    context.commit('setUserDetails', payload.value);
  },
  setUserToken(context, payload) {
    context.commit('setUserToken', payload.value);
  },
  setUserTokenExpiration(context, payload) {
    context.commit('setUserTokenExpiration', payload);
  },
  autoLogin(context) {
    // PREVENT AUTOLOGIN IF COMING FROM LOGOUT ROUTE
    if (context.rootState.route.to !== 'logout') {
      //console.log('TRYING TO AUTOLOGIN');
      //console.log(context);
      //console.log(payload);

      const token = localStorage.getItem('sc-dashboard-token');
      const tokenExpiration = localStorage.getItem('sc-dashboard-tokenexpiration');
      //const userDetails = JSON.parse(localStorage.getItem('sc-dashboard-userdetails'));
      const userDetails = JSON.parse(sessionStorage.getItem('sc-dashboard-userdetails'));

      // console.log('token');
      // console.log(token);

      // console.log('tokenExpiration');
      // console.log(tokenExpiration);

      // console.log('userDetails');
      // console.log(userDetails);

      if (userDetails !== null) {
        if (token && tokenExpiration && userDetails.username) {
          context.dispatch('setUser', {
            userLoggedIn: true,
            userName: userDetails.username,
            userAvatar: userDetails.data.logo,
            userDetails: userDetails,
            userToken: token,
            userTokenExpiration: +tokenExpiration
          });
          context.dispatch(
            'notifications/getNotifications',
            {
              sort: {
                created_at: {
                  active: true,
                  order: 'DESC'
                }
              },
              limit: 30,
              filters: [
                {
                  name: 'read',
                  value: 0
                }
              ],
              updateStore: true
            },
            {
              root: true
            }
          );
          context.dispatch(
            'websocketDashboard/connect',
            {},
            {
              root: true
            }
          );
          context.dispatch(
            'websocket/connect',
            {},
            {
              root: true
            }
          );
        }
      }
    }
  },
  autoLogout(context) {
    context.commit('resetUser');
    context.commit(
      'notifications/resetNotifications',
      {},
      {
        root: true
      }
    );
    context.dispatch(
      'websocketDashboard/disconnect',
      {},
      {
        root: true
      }
    );
    context.dispatch(
      'websocket/disconnect',
      {},
      {
        root: true
      }
    );
    clearTimeout(timer);
    timer = null;
    router.replace({
      name: 'login'
    });
  },
  async login(context, payload) {
    try {
      const config = {
        method: 'post',
        url: process.env.VUE_APP_APIURL + '/users/authenticate',
        headers: {
          'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          username: payload.email,
          password: payload.password,
          userEnteredCode: payload.userEnteredCode,
          platform_uuid: process.env.VUE_APP_PLATFORMID
        })
      };

      const response = await axios(config);

      if (response.data.success) {
        // Sets the token time to live in MS (1h)
        //const tokenExpiration = 3600 * 1000;
        const tokenExpiration = 172800 * 1000; // 2 days
        const tokenExpirationDate = new Date().getTime() + tokenExpiration;

        if (response.data.result === '2fa-waiting') {
          context.dispatch('setUserName', {
            value: payload.email
          });
          context.dispatch('setPassword', {
            value: payload.password
          });
          router.push({
            name: 'login-2fa'
          });
          // router.push({
          //   name: 'login-2fa',
          //   params: {
          //     email: payload.email,
          //     password: payload.password
          //   }
          // });
        } else {
          //console.log(context);
          // console.log(payload);
          // console.log(response);
          context.dispatch('setUser', {
            userLoggedIn: true,
            userName: payload.email,
            userAvatar: response.data.result.data.logo,
            userDetails: response.data.result,
            userToken: response.data.token,
            userTokenExpiration: tokenExpirationDate
          });
          context.dispatch(
            'notifications/getNotifications',
            {
              sort: {
                created_at: {
                  active: true,
                  order: 'DESC'
                }
              },
              limit: 30,
              filters: [
                {
                  name: 'read',
                  value: 0
                }
              ],
              updateStore: true
            },
            {
              root: true
            }
          );
          context.dispatch(
            'websocketDashboard/connect',
            {},
            {
              root: true
            }
          );
          context.dispatch(
            'websocket/connect',
            {},
            {
              root: true
            }
          );
          router.push({
            name: 'dashboard'
          });
        }
      }
      return response;
    } catch (err) {
      return err.response;
    }
  },
  logout(context) {
    context.commit('resetUser');
    // context.commit(
    //   'notifications/resetNotifications',
    //   {},
    //   {
    //     root: true
    //   }
    // );
    // context.commit(
    //   'chat/resetMessages',
    //   {},
    //   {
    //     root: true
    //   }
    // );
    context.dispatch(
      'websocketDashboard/disconnect',
      {},
      {
        root: true
      }
    );
    context.dispatch(
      'websocket/disconnect',
      {},
      {
        root: true
      }
    );
    clearTimeout(timer);
    timer = null;
  },
  async forgotPassword(context, payload) {
    try {
      const config = {
        method: 'post',
        url: process.env.VUE_APP_APIURL + '/users/resetpassword',
        headers: {
          'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          username: payload.email
        })
      };

      const response = await axios(config);

      return response;
    } catch (err) {
      return err.response;
    }
  },
  async resetPassword(context, payload) {
    try {
      const config = {
        method: 'post',
        url: process.env.VUE_APP_APIURL + '/users/resetpassword/' + payload.uuid + '/' + payload.token,
        headers: {
          'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          password: payload.password,
          passwordConfirmation: payload.password_confirmation
        })
      };

      const response = await axios(config);

      return response;
    } catch (err) {
      return err.response;
    }
  },
  async register(context, payload) {
    try {
      // TEST
      const config = {
        method: 'post',
        url: process.env.VUE_APP_APIURL + '/users/register',
        headers: {
          'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          fullname: payload.fullname,
          email: payload.email,
          password: payload.password
        })
      };

      const response = await axios(config);

      return response;
    } catch (err) {
      return err.response;
    }
  }
};
