import layoutActions from '@/store/layout/actions.js';
import layoutGetters from '@/store/layout/getters.js';
import layoutMutations from '@/store/layout/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      layoutType: 'vertical',
      layoutWidth: 'fluid',
      sidebarSize: 'lg',
      topbar: 'light',
      mode: 'light',
      position: 'fixed',
      sidebarView: 'default',
      sidebarColor: 'dark'
    };
  },
  actions: layoutActions,
  getters: layoutGetters,
  mutations: layoutMutations
};
