import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// import AOS from 'aos';
// import 'aos/dist/aos.css';

import i18n from './i18n';
import store from './store/index.js';

import BootstrapVue3 from 'bootstrap-vue-3';
// import BootstrapVue3 from 'bootstrap-vue-next';
import vClickOutside from 'click-outside-vue3';
import VueApexCharts from 'vue3-apexcharts';
// import Maska from 'maska';

import VueFeather from 'vue-feather';
//import Particles from 'particles.vue3';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
// import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

// import VuePlyr from 'vue-plyr';
// import 'vue-plyr/dist/vue-plyr.css';

import 'simplebar/dist/simplebar.min.css';
import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
// import '@/assets/scss/mermaid.min.css';
import '@vueform/multiselect/themes/default.css';

import moment from 'moment';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// AOS.init({
//   easing: 'ease-out-back',
//   duration: 1000
// });

// createApp(App)
//   .use(store)
//   .use(router)
//   .use(VueApexCharts)
//   .use(require('vue-moment'), {
//     moment
//   })
//   .use(BootstrapVue3)
//   .component(VueFeather.type, VueFeather)
//   .use(Maska)
//   .use(Particles)
//   // .use(VuePlyr, {
//   //   plyr: {}
//   // })
//   .use(i18n)
//   .use(vClickOutside)
//   .mount('#app');

const app = createApp(App);

app.config.globalProperties.$moment = moment;

app
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  // .use(vMaska)
  .use(Toast, {
    transition: 'Vue-Toastification__bounce',
    maxToasts: 20,
    newestOnTop: true
  })
  //.use(Particles)
  // .use(VuePlyr, {
  //   plyr: {}
  // })
  .use(i18n)
  .use(vClickOutside)
  .mount('#app');
