export default {
  resetNotifications(state) {
    state.all = [];
    state.alerts = [];
    state.messages = [];
    //window.sessionStorage.removeItem('vuex');
    //window.sessionStorage.clear();
  },
  markAsRead(state, payload) {
    //console.log(state);
    //console.log(payload);
    payload.read = true;
  },
  setNotification(state, payload) {
    //console.log(payload);
    if (payload.notification.type === 'ALERT') {
      state.alerts.unshift(payload.notification);
      //state.alerts.push(payload);
    } else if (payload.notification.type === 'MESSAGE') {
      state.messages.unshift(payload.notification);
      //state.messages.push(payload);
    }
    state.all.unshift(payload.notification);
    //state.all.push(payload);
    // console.log(state);
    // console.log(payload);
  },
  setNotifications(state, payload) {
    // console.log('setNotifications');
    // console.log(payload);
    for (let notification of payload) {
      //console.log(notification);
      if (notification.type === 'ALERT') {
        state.alerts.push(notification);
      } else if (notification.type === 'MESSAGE') {
        state.messages.push(notification);
      }
      state.all.push(notification);
    }
    //console.log(state);
  }
};
