export default {
  getMessages(state) {
    if (state.room.type === 'organization') {
      if (typeof state.messages.organizations[state.room.name] !== 'undefined') {
        return state.messages.organizations[state.room.name];
      }
    } else if (state.room.type === 'client') {
      if (typeof state.messages.clients[state.room.name] !== 'undefined') {
        return state.messages.clients[state.room.name];
      }
    } else if (state.room.type === 'division') {
      if (typeof state.messages.divisions[state.room.name] !== 'undefined') {
        return state.messages.divisions[state.room.name];
      }
    } else if (state.room.type === 'event') {
      if (typeof state.messages.events[state.room.name] !== 'undefined') {
        return state.messages.events[state.room.name];
      }
    } else if (state.room.type === 'breakout') {
      if (typeof state.messages.breakouts[state.room.name] !== 'undefined') {
        return state.messages.breakouts[state.room.name];
      }
    }
  },
  getRoom(state) {
    return state.room;
  },
  getRooms(state) {
    return state.rooms;
  },
  getUsers(state) {
    return state.users;
  },
  getUsersCount(state) {
    // console.log(state);
    return state.users.length;
  }
};
