export default {
  getIsConnected(state) {
    return state.isConnected;
  },
  getWs(state) {
    return state.ws;
  },
  getActiveCustomFunctions(state) {
    return state.activeCustomFunctions;
  },
  getCustomFunctions(state) {
    return state.customFunctions;
  },
  getStatistics(state) {
    return state.statistics;
  },
  getGlobalBandwidth(state) {
    return state.statistics.computedStatistics.globalBandwidth;
  },
  getGlobalResolutions(state) {
    return state.statistics.computedStatistics.globalResolutions;
  },
  getGlobalPlayerStatuses(state) {
    return state.statistics.computedStatistics.globalPlayerStatuses;
  },
  getGlobalOs(state) {
    return state.statistics.computedStatistics.globalOs;
  },
  getGlobalViewers(state) {
    return state.statistics.computedStatistics.globalViewers;
  },
  getGlobalCountries(state) {
    return state.statistics.statistics.countries;
  },
  getGlobalRegions(state) {
    return state.statistics.statistics.regions;
  }
};
