export default {
  getUser(state) {
    return state;
  },
  getUserAvatarLarge(state) {
    return state.userAvatar.large;
  },
  getUserAvatarSmall(state) {
    return state.userAvatar.small;
  },
  getUserLoggedIn(state) {
    return state.userLoggedIn;
  },
  getUserName(state) {
    if (state.userName) {
      return state.userName;
    } else {
      return 'unknown';
    }
  },
  getUserPassword(state) {
    if (state.userPassword) {
      return state.userPassword;
    } else {
      return '';
    }
  },
  getUserFullname(state) {
    if (state.userDetails && state.userDetails.data && state.userDetails.data.fullname) {
      return state.userDetails.data.fullname;
    } else {
      return 'unknown';
    }
  },
  getUserTitle(state) {
    if (state.userDetails && state.userDetails.data && state.userDetails.data.title) {
      return state.userDetails.data.title;
    } else {
      return 'unknown';
    }
  },
  getUserDetails(state) {
    return state.userDetails;
  },
  getUserToken(state) {
    return state.userToken;
  },
  getUserTokenExpiration(state) {
    return state.userTokenExpiration;
  },
  getUserUuid(state) {
    return state.userDetails.uuid;
  },
  getUserOrganization(state) {
    if (state.userDetails.organization != null) {
      if (typeof state.userDetails.organization.name !== 'undefined') {
        return state.userDetails.organization.name;
      }
    }
    return '';
  },
  getUserClient(state) {
    if (state.userDetails.client != null) {
      if (typeof state.userDetails.client.name !== 'undefined') {
        return state.userDetails.client.name;
      }
    }
    return '';
  },
  getUserDivision(state) {
    if (state.userDetails.division != null) {
      if (typeof state.userDetails.division.name !== 'undefined') {
        return state.userDetails.division.name;
      }
    }
    return '';
  }
};
