import eventsActions from '@/store/events/actions.js';
import eventsGetters from '@/store/events/getters.js';
import eventsMutations from '@/store/events/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: []
    };
  },
  actions: eventsActions,
  getters: eventsGetters,
  mutations: eventsMutations
};
