import usersRolesActions from '@/store/usersRoles/actions.js';
import usersRolesGetters from '@/store/usersRoles/getters.js';
import usersRolesMutations from '@/store/usersRoles/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: []
    };
  },
  actions: usersRolesActions,
  getters: usersRolesGetters,
  mutations: usersRolesMutations
};
