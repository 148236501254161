//import router from '@/router/index.js';
import axios from 'axios';
// import Swal from 'sweetalert2';

export default {
  setProfile(context, payload) {
    context.commit('setProfile', payload.value);
  },
  async updateProfile(context, payload) {
    //console.log(context);
    //console.log(payload);

    try {
      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/' + context.rootState.authentication.userDetails.uuid);

      const config = {
        method: 'put',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        },
        data: payload
      };

      const response = await axios(config);

      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async getProfile(context) {
    try {
      // console.log(context);
      //console.log(payload);

      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/' + context.rootState.authentication.userDetails.uuid);

      //console.log(fetchUrl.href);

      const config = {
        method: 'get',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };

      const response = await axios(config);

      //if (response.data.success) {
      // // Sets the token time to live in MS (1h)
      // //const tokenExpiration = 3600 * 1000;
      // const tokenExpiration = 3600 * 1000;
      // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
      // if (response.data.result === 'Waiting for 2FA code') {
      //   context.dispatch('setUserName', {
      //     value: payload.email
      //   });
      //   router.push({
      //     name: 'login-2fa',
      //     params: {
      //       email: payload.email,
      //       password: payload.password
      //     }
      //   });
      // } else {
      //   context.dispatch('setUser', {
      //     userLoggedIn: true,
      //     userName: payload.email,
      //     userDetails: response.data.result,
      //     userToken: response.data.token,
      //     userTokenExpiration: tokenExpirationDate
      //   });
      //   router.push({
      //     name: 'dashboard'
      //   });
      // }
      //}
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async getProfileQrCode(context) {
    try {
      // console.log(context);
      //console.log(payload);

      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/qrcode/' + context.rootState.authentication.userDetails.uuid);

      //console.log(fetchUrl.href);

      const config = {
        method: 'get',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };

      const response = await axios(config);

      //if (response.data.success) {
      // // Sets the token time to live in MS (1h)
      // //const tokenExpiration = 3600 * 1000;
      // const tokenExpiration = 3600 * 1000;
      // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
      // if (response.data.result === 'Waiting for 2FA code') {
      //   context.dispatch('setUserName', {
      //     value: payload.email
      //   });
      //   router.push({
      //     name: 'login-2fa',
      //     params: {
      //       email: payload.email,
      //       password: payload.password
      //     }
      //   });
      // } else {
      //   context.dispatch('setUser', {
      //     userLoggedIn: true,
      //     userName: payload.email,
      //     userDetails: response.data.result,
      //     userToken: response.data.token,
      //     userTokenExpiration: tokenExpirationDate
      //   });
      //   router.push({
      //     name: 'dashboard'
      //   });
      // }
      //}
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  async savePassword(context, payload) {
    try {
      const config = {
        method: 'post',
        url: process.env.VUE_APP_APIURL + '/users/savepassword/' + context.rootState.authentication.userDetails.uuid,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        },
        data: JSON.stringify({
          password: payload.password
        })
      };

      const response = await axios(config);

      return response;
    } catch (err) {
      return err.response;
    }
  },
  async uploadProfileLogo(context, payload) {
    //console.log(context);
    //console.log(payload.properties);

    let formData = new FormData();
    formData.append('name', payload.name);
    formData.append('folder', payload.folder);
    formData.append('asset', payload.asset);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/clouds/aws/s3/upload/resize');

    const config = {
      method: 'post',
      url: fetchUrl.href,
      data: formData,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        //'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
        //'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      },
      // transformRequest: (data, error) => {
      //   console.log(data);
      //   console.log(error);
      //   return formData;
      // },
      onUploadProgress: (progressEvent) => {
        // use upload data, since it's an upload progress
        // iOS: {"isTrusted": false, "lengthComputable": true, "loaded": 123, "total": 98902}
        //setlogoUploadPercentage(parseInt(Math.round((progressEvent.loaded/progressEvent.total) * 100)));
        //console.log(percent);
        context.commit('setlogoUploadPercentage', { value: parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)) });
      }
    };

    const response = await axios(config);

    return response;
  }
};
