import websocketActions from '@/store/websocket/actions.js';
import websocketGetters from '@/store/websocket/getters.js';
import websocketMutations from '@/store/websocket/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      ws: null,
      isConnected: false,
      statistics: {
        computedStatistics: {
          globalBandwidth: 0,
          globalResolutions: {},
          globalPlayerStatuses: {
            playing: 0,
            buffering: 0,
            erroring: 0,
            idling: 0,
            other: 0,
            total: 0
          },
          globalOs: {
            windows: 0,
            macos: 0,
            ios: 0,
            android: 0,
            other: 0
          },
          globalViewers: {
            prelive: 0,
            live: 0,
            tempstop: 0,
            noarchive: 0,
            prearchive: 0,
            archive: 0,
            complete: 0,
            unknown: 0,
            dashboard: 0,
            watching: 0,
            buffering: 0,
            errors: 0,
            total: 0
          }
        },
        statistics: {
          countries: {},
          regions: {}
        }
      },
      activeCustomFunctions: null,
      customFunctions: null
    };
  },
  actions: websocketActions,
  getters: websocketGetters,
  mutations: websocketMutations
};
