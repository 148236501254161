import divisionsActions from '@/store/divisions/actions.js';
import divisionsGetters from '@/store/divisions/getters.js';
import divisionsMutations from '@/store/divisions/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: [],
      logoUploadPercentage: 0
    };
  },
  actions: divisionsActions,
  getters: divisionsGetters,
  mutations: divisionsMutations
};
