//import router from '@/router/index.js';
import axios from 'axios';

export default {
  async getGlobalBandwidthData(context, payload) {
    // console.log(context);
    // console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/reports/bandwidth/global/' + payload.value);
    // fetchUrl.searchParams.set('_page', payload.page);
    // fetchUrl.searchParams.set('_limit', payload.limit);
    // fetchUrl.searchParams.set('_offset', payload.offset);

    // let sortString = '';
    // Object.keys(payload.sort).forEach(function (item) {
    //   if (payload.sort[item].active) {
    //     sortString += item + ':' + payload.sort[item].order + ',';
    //   }
    // });

    // if (sortString !== '') {
    //   fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
    // }

    // let filtersString = '';
    // payload.filters.forEach((element) => {
    //   if (element.name === 'state' || element.name === 'locked' || element.name === 'deleted') {
    //     if (element.value === true) {
    //       filtersString += element.name + ':1,';
    //     } else {
    //       filtersString += element.name + ':0,';
    //     }
    //   } else {
    //     filtersString += element.name + ':' + element.value + ',';
    //   }
    // });

    // if (filtersString !== '') {
    //   fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
    // }

    // //console.log(fetchUrl.href);

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    //if (response.data.success) {
    // // Sets the token time to live in MS (1h)
    // //const tokenExpiration = 3600 * 1000;
    // const tokenExpiration = 3600 * 1000;
    // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
    // if (response.data.result === 'Waiting for 2FA code') {
    //   context.dispatch('setUserName', {
    //     value: payload.email
    //   });
    //   router.push({
    //     name: 'login-2fa',
    //     params: {
    //       email: payload.email,
    //       password: payload.password
    //     }
    //   });
    // } else {
    //   context.dispatch('setUser', {
    //     userLoggedIn: true,
    //     userName: payload.email,
    //     userDetails: response.data.result,
    //     userToken: response.data.token,
    //     userTokenExpiration: tokenExpirationDate
    //   });
    //   router.push({
    //     name: 'dashboard'
    //   });
    // }
    //}
    return response;
  },
  async getOrganizationBandwidthData(context, payload) {
    // console.log(context);
    // console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/reports/bandwidth/organization/' + payload.uuid + '/' + payload.year);

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    return response;
  },
  // async getOrganizationByUuid(context, payload) {
  //   // console.log(context);
  //   //console.log(payload);

  //   let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/organizations/' + payload.id);

  //   //console.log(fetchUrl.href);

  //   const config = {
  //     method: 'get',
  //     url: fetchUrl.href,
  //     headers: {
  //       //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + context.rootState.authentication.userToken
  //     }
  //   };

  //   const response = await axios(config);

  //   //if (response.data.success) {
  //   // // Sets the token time to live in MS (1h)
  //   // //const tokenExpiration = 3600 * 1000;
  //   // const tokenExpiration = 3600 * 1000;
  //   // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
  //   // if (response.data.result === 'Waiting for 2FA code') {
  //   //   context.dispatch('setUserName', {
  //   //     value: payload.email
  //   //   });
  //   //   router.push({
  //   //     name: 'login-2fa',
  //   //     params: {
  //   //       email: payload.email,
  //   //       password: payload.password
  //   //     }
  //   //   });
  //   // } else {
  //   //   context.dispatch('setUser', {
  //   //     userLoggedIn: true,
  //   //     userName: payload.email,
  //   //     userDetails: response.data.result,
  //   //     userToken: response.data.token,
  //   //     userTokenExpiration: tokenExpirationDate
  //   //   });
  //   //   router.push({
  //   //     name: 'dashboard'
  //   //   });
  //   // }
  //   //}
  //   return response;
  // }
  async getClientBandwidthData(context, payload) {
    // console.log(context);
    // console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/reports/bandwidth/client/' + payload.uuid + '/' + payload.year);

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    return response;
  }
};
