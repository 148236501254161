import usersActions from '@/store/users/actions.js';
import usersGetters from '@/store/users/getters.js';
import usersMutations from '@/store/users/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: [],
      logoUploadPercentage: 0
    };
  },
  actions: usersActions,
  getters: usersGetters,
  mutations: usersMutations
};
