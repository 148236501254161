import websocketDashboardActions from '@/store/websocket-dashboard/actions.js';
import websocketDashboardGetters from '@/store/websocket-dashboard/getters.js';
import websocketDashboardMutations from '@/store/websocket-dashboard/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      ws: null,
      isConnected: false,
      data: null
    };
  },
  actions: websocketDashboardActions,
  getters: websocketDashboardGetters,
  mutations: websocketDashboardMutations
};
