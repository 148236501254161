export default {
  // getNotifications: (state) => (type) => {
  //   // console.log('NEW GETTER123');
  //   // console.log(state);
  //   // console.log(type);
  //   if (type === 'all') {
  //     return state.all;
  //   } else if (type === 'alerts') {
  //     return state.alerts;
  //   } else if (type === 'messages') {
  //     return state.messages;
  //   }
  //   return state.all;
  // },
  // getNotificationsCount: (state) => (type) => {
  //   console.log('NEW GETTER 2');
  //   // console.log(state);
  //   // console.log(type);
  //   let unreadsCount;
  //   if (type === 'all') {
  //     unreadsCount = state.all.filter(function(notification) {
  //       return notification.read === false;
  //     }).length;
  //     return unreadsCount;
  //   } else if (type === 'alerts') {
  //     unreadsCount = state.alerts.filter(function(notification) {
  //       return notification.read === false;
  //     }).length;
  //     return unreadsCount;
  //   } else if (type === 'messages') {
  //     unreadsCount = state.messages.filter(function(notification) {
  //       return notification.read === false;
  //     }).length;
  //   } else {
  //     unreadsCount = state.all.filter(function(notification) {
  //       return notification.read === false;
  //     }).length;
  //   }
  //   return unreadsCount;
  // }
  getAllNotifications: (state) => () => {
    //console.log('NEW GETTER 1 ALL');
    return state.all;
  },
  getAlertsNotifications: (state) => () => {
    //console.log('NEW GETTER 1 alerts');
    return state.alerts;
  },
  getMessagesNotifications: (state) => () => {
    //console.log('NEW GETTER 1 messages');
    return state.messages;
  },
  getAllNotificationsCount: (state) => () => {
    //console.log('NEW GETTER 2 ALL');
    // console.log(state);
    // console.log(type);
    return state.all.filter(function (notification) {
      return notification.read === false;
    }).length;
  },
  getAlertsNotificationsCount: (state) => () => {
    //console.log('NEW GETTER 2 alerts');
    // console.log(state);
    // console.log(type);
    return state.alerts.filter(function (notification) {
      return notification.read === false;
    }).length;
  },
  getMessagesNotificationsCount: (state) => () => {
    //console.log('NEW GETTER 2 messages');
    // console.log(state);
    // console.log(type);
    return state.messages.filter(function (notification) {
      return notification.read === false;
    }).length;
  }
};
