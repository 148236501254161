export default {
  resetUser(state) {
    state.userLoggedIn = false;
    state.userName = null;
    state.userAvatar = {
      original: require('@/assets/images/logos/pqm_dark_128.png'),
      large: require('@/assets/images/logos/pqm_dark_128.png'),
      small: require('@/assets/images/logos/studiocast_light_80.png'),
      icon: require('@/assets/images/logos/studiocast_light_80.png')
    };
    state.userDetails = {};
    state.userToken = null;
    state.userTokenExpiration = null;
    localStorage.removeItem('sc-dashboard-token');
    localStorage.removeItem('sc-dashboard-tokenexpiration');
    //localStorage.removeItem('sc-dashboard-userdetails');
    sessionStorage.removeItem('sc-dashboard-userdetails');
  },
  setUser(state, payload) {
    // console.log('mutation-setUser');
    // console.log(payload);
    state.userLoggedIn = payload.userLoggedIn;
    state.userName = payload.userName;
    state.userAvatar = payload.userAvatar;
    state.userDetails = payload.userDetails;
    state.userToken = payload.userToken;
    state.userTokenExpiration = payload.userTokenExpiration;
    localStorage.setItem('sc-dashboard-token', payload.userToken);
    localStorage.setItem('sc-dashboard-tokenexpiration', payload.userTokenExpiration);
    //localStorage.setItem('sc-dashboard-userdetails', JSON.stringify(payload.userDetails));
    sessionStorage.setItem('sc-dashboard-userdetails', JSON.stringify(payload.userDetails));
  },
  setAutoLogout(state, payload) {
    state.userAutoLogout = payload;
  },
  setUserLoggedIn(state, payload) {
    state.userLoggedIn = payload;
  },
  setUserName(state, payload) {
    state.userName = payload;
  },
  setPassword(state, payload) {
    state.userPassword = payload;
  },
  setUserDetails(state, payload) {
    state.userDetails = payload;
    sessionStorage.setItem('sc-dashboard-userdetails', JSON.stringify(payload));
  },
  setUserToken(state, payload) {
    state.userToken = payload;
    localStorage.setItem('sc-dashboard-token', payload);
  },
  setUserTokenExpiration(state, payload) {
    state.userTokenExpiration = payload;
    localStorage.setItem('sc-dashboard-tokenexpiration', payload);
  },
  setUserAvatar(state, payload) {
    state.userAvatar = payload;
  }
};
