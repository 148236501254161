//import router from '@/router/index.js';
import axios from 'axios';
//import Swal from 'sweetalert2';

export default {
  // setCountry(context, payload) {
  //   context.commit('setCountry', payload.value);
  // },
  async getCountries(context, payload) {
    // console.log('context');
    // console.log(context);

    // console.log('payload');
    // console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/base/countries');
    // fetchUrl.searchParams.set('_page', payload.page);
    fetchUrl.searchParams.set('_limit', payload.limit);
    // fetchUrl.searchParams.set('_offset', payload.offset);

    let sortString = '';
    Object.keys(payload.sort).forEach(function (item) {
      if (payload.sort[item].active) {
        sortString += item + ':' + payload.sort[item].order + ',';
      }
    });

    if (sortString !== '') {
      fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
    }

    // let filtersString = '';
    // payload.filters.forEach((element) => {
    //   if (element.value === true) {
    //     filtersString += element.name + ':1,';
    //   } else {
    //     filtersString += element.name + ':0,';
    //   }
    // });

    // if (filtersString !== '') {
    //   fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
    // }

    //console.log(fetchUrl.href);

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    if (response.data.success) {
      // // Sets the token time to live in MS (1h)
      // //const tokenExpiration = 3600 * 1000;
      // const tokenExpiration = 3600 * 1000;
      // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
      // if (response.data.result === 'Waiting for 2FA code') {
      //   context.dispatch('setUserName', {
      //     value: payload.email
      //   });
      //   router.push({
      //     name: 'login-2fa',
      //     params: {
      //       email: payload.email,
      //       password: payload.password
      //     }
      //   });
      // } else {
      //   context.dispatch('setUser', {
      //     userLoggedIn: true,
      //     userName: payload.email,
      //     userDetails: response.data.result,
      //     userToken: response.data.token,
      //     userTokenExpiration: tokenExpirationDate
      //   });
      //   router.push({
      //     name: 'dashboard'
      //   });
      // }
    }
    return response;
  },
  async getProvinces(context, payload) {
    // console.log('context');
    // console.log(context);

    // console.log('payload');
    // console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/base/provinces');
    // fetchUrl.searchParams.set('_page', payload.page);
    fetchUrl.searchParams.set('_limit', payload.limit);
    // fetchUrl.searchParams.set('_offset', payload.offset);

    let sortString = '';
    Object.keys(payload.sort).forEach(function (item) {
      if (payload.sort[item].active) {
        sortString += item + ':' + payload.sort[item].order + ',';
      }
    });

    if (sortString !== '') {
      fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
    }

    // let filtersString = '';
    // payload.filters.forEach((element) => {
    //   if (element.value === true) {
    //     filtersString += element.name + ':1,';
    //   } else {
    //     filtersString += element.name + ':0,';
    //   }
    // });

    // if (filtersString !== '') {
    //   fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
    // }

    //console.log(fetchUrl.href);

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    if (response.data.success) {
      // // Sets the token time to live in MS (1h)
      // //const tokenExpiration = 3600 * 1000;
      // const tokenExpiration = 3600 * 1000;
      // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
      // if (response.data.result === 'Waiting for 2FA code') {
      //   context.dispatch('setUserName', {
      //     value: payload.email
      //   });
      //   router.push({
      //     name: 'login-2fa',
      //     params: {
      //       email: payload.email,
      //       password: payload.password
      //     }
      //   });
      // } else {
      //   context.dispatch('setUser', {
      //     userLoggedIn: true,
      //     userName: payload.email,
      //     userDetails: response.data.result,
      //     userToken: response.data.token,
      //     userTokenExpiration: tokenExpirationDate
      //   });
      //   router.push({
      //     name: 'dashboard'
      //   });
      // }
    }
    return response;
  },
  async getTimezones(context, payload) {
    // console.log('context');
    // console.log(context);

    // console.log('payload');
    // console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/base/timezones');
    // fetchUrl.searchParams.set('_page', payload.page);
    fetchUrl.searchParams.set('_limit', payload.limit);
    // fetchUrl.searchParams.set('_offset', payload.offset);

    let sortString = '';
    Object.keys(payload.sort).forEach(function (item) {
      if (payload.sort[item].active) {
        sortString += item + ':' + payload.sort[item].order + ',';
      }
    });

    if (sortString !== '') {
      fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
    }

    // let filtersString = '';
    // payload.filters.forEach((element) => {
    //   if (element.value === true) {
    //     filtersString += element.name + ':1,';
    //   } else {
    //     filtersString += element.name + ':0,';
    //   }
    // });

    // if (filtersString !== '') {
    //   fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
    // }

    //console.log(fetchUrl.href);

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    if (response.data.success) {
      // // Sets the token time to live in MS (1h)
      // //const tokenExpiration = 3600 * 1000;
      // const tokenExpiration = 3600 * 1000;
      // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
      // if (response.data.result === 'Waiting for 2FA code') {
      //   context.dispatch('setUserName', {
      //     value: payload.email
      //   });
      //   router.push({
      //     name: 'login-2fa',
      //     params: {
      //       email: payload.email,
      //       password: payload.password
      //     }
      //   });
      // } else {
      //   context.dispatch('setUser', {
      //     userLoggedIn: true,
      //     userName: payload.email,
      //     userDetails: response.data.result,
      //     userToken: response.data.token,
      //     userTokenExpiration: tokenExpirationDate
      //   });
      //   router.push({
      //     name: 'dashboard'
      //   });
      // }
    }
    return response;
  },
  async getLanguages(context, payload) {
    // console.log('context');
    // console.log(context);

    // console.log('payload');
    // console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/base/languages');
    // fetchUrl.searchParams.set('_page', payload.page);
    fetchUrl.searchParams.set('_limit', payload.limit);
    // fetchUrl.searchParams.set('_offset', payload.offset);

    let sortString = '';
    Object.keys(payload.sort).forEach(function (item) {
      if (payload.sort[item].active) {
        sortString += item + ':' + payload.sort[item].order + ',';
      }
    });

    if (sortString !== '') {
      fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
    }

    // let filtersString = '';
    // payload.filters.forEach((element) => {
    //   if (element.value === true) {
    //     filtersString += element.name + ':1,';
    //   } else {
    //     filtersString += element.name + ':0,';
    //   }
    // });

    // if (filtersString !== '') {
    //   fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
    // }

    //console.log(fetchUrl.href);

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    if (response.data.success) {
      // // Sets the token time to live in MS (1h)
      // //const tokenExpiration = 3600 * 1000;
      // const tokenExpiration = 3600 * 1000;
      // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
      // if (response.data.result === 'Waiting for 2FA code') {
      //   context.dispatch('setUserName', {
      //     value: payload.email
      //   });
      //   router.push({
      //     name: 'login-2fa',
      //     params: {
      //       email: payload.email,
      //       password: payload.password
      //     }
      //   });
      // } else {
      //   context.dispatch('setUser', {
      //     userLoggedIn: true,
      //     userName: payload.email,
      //     userDetails: response.data.result,
      //     userToken: response.data.token,
      //     userTokenExpiration: tokenExpirationDate
      //   });
      //   router.push({
      //     name: 'dashboard'
      //   });
      // }
    }
    return response;
  },
  async cloneCountries(context, payload) {
    console.log(context);
    console.log(payload);
    // const userResponse = await Swal.fire({
    //   title: payload.i18n('t-organizations-clone-areyousure'),
    //   showCancelButton: true,
    //   confirmButtonColor: '#1367c8',
    //   confirmButtonText: payload.i18n('t-yes'),
    //   cancelButtonColor: '#0ab39c',
    //   cancelButtonText: payload.i18n('t-cancel'),
    // });
    // if (userResponse.isConfirmed) {
    //   let fetchUrl = new URL(
    //     process.env.VUE_APP_APIURL + '/organizations/' + payload.id + '/clone'
    //   );
    //   const config = {
    //     method: 'post',
    //     url: fetchUrl.href,
    //     headers: {
    //       //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
    //       'Content-Type': 'application/json',
    //       Authorization: 'Bearer ' + context.rootState.authentication.userToken
    //     }
    //   };
    //   const response = await axios(config);
    //   return response;
    // } else {
    //   return false;
    // }
  },
  async deleteCountry(context, payload) {
    console.log(context);
    console.log(payload);
    // const userResponse = await Swal.fire({
    //   title: payload.i18n('t-organizations-delete-areyousure'),
    //   text: payload.i18n('t-organizations-delete-confirm'),
    //   showCancelButton: true,
    //   confirmButtonColor: '#1367c8',
    //   confirmButtonText: payload.i18n('t-yes'),
    //   cancelButtonColor: '#0ab39c',
    //   cancelButtonText: payload.i18n('t-cancel'),
    // });
    // if (userResponse.isConfirmed) {
    //   let fetchUrl = new URL(
    //     process.env.VUE_APP_APIURL + '/organizations/' + payload.id
    //   );
    //   const config = {
    //     method: 'delete',
    //     url: fetchUrl.href,
    //     headers: {
    //       //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
    //       'Content-Type': 'application/json',
    //       Authorization: 'Bearer ' + context.rootState.authentication.userToken
    //     }
    //   };
    //   const response = await axios(config);
    //   return response;
    // } else {
    //   return false;
    // }
  },
  async getCountryByUuid(context, payload) {
    console.log(context);
    console.log(payload);

    // let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/organizations/' + payload.id);

    // //console.log(fetchUrl.href);

    // const config = {
    //   method: 'get',
    //   url: fetchUrl.href,
    //   headers: {
    //     //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
    //     'Content-Type': 'application/json',
    //     Authorization: 'Bearer ' + context.rootState.authentication.userToken
    //   }
    // };

    // const response = await axios(config);

    // //if (response.data.success) {
    //   // // Sets the token time to live in MS (1h)
    //   // //const tokenExpiration = 3600 * 1000;
    //   // const tokenExpiration = 3600 * 1000;
    //   // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
    //   // if (response.data.result === 'Waiting for 2FA code') {
    //   //   context.dispatch('setUserName', {
    //   //     value: payload.email
    //   //   });
    //   //   router.push({
    //   //     name: 'login-2fa',
    //   //     params: {
    //   //       email: payload.email,
    //   //       password: payload.password
    //   //     }
    //   //   });
    //   // } else {
    //   //   context.dispatch('setUser', {
    //   //     userLoggedIn: true,
    //   //     userName: payload.email,
    //   //     userDetails: response.data.result,
    //   //     userToken: response.data.token,
    //   //     userTokenExpiration: tokenExpirationDate
    //   //   });
    //   //   router.push({
    //   //     name: 'dashboard'
    //   //   });
    //   // }
    // //}
    // return response;
  }
};
