import usersGroupsActions from '@/store/usersGroups/actions.js';
import usersGroupsGetters from '@/store/usersGroups/getters.js';
import usersGroupsMutations from '@/store/usersGroups/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: []
    };
  },
  actions: usersGroupsActions,
  getters: usersGroupsGetters,
  mutations: usersGroupsMutations
};
