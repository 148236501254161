import platformsActions from '@/store/platforms/actions.js';
import platformsGetters from '@/store/platforms/getters.js';
import platformsMutations from '@/store/platforms/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: [],
      logoUploadPercentage: 0
    };
  },
  actions: platformsActions,
  getters: platformsGetters,
  mutations: platformsMutations
};
