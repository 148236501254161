import schedulerLogsActions from '@/store/schedulerLogs/actions.js';
import schedulerLogsGetters from '@/store/schedulerLogs/getters.js';
import schedulerLogsMutations from '@/store/schedulerLogs/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: []
    };
  },
  actions: schedulerLogsActions,
  getters: schedulerLogsGetters,
  mutations: schedulerLogsMutations
};
