import { getCurrentTimeStamp } from '@/common';

let timer = null;
let socketSupport = false;
let ws = null;

let waitTimeout = null;
// let waitInterval = 500;
let autoReconnect = true;
let autoReconnectInterval = 30000;
let autoReconnectNumRetries = 10;
let autoReconnectRetriesCounter = 1;
let autoReconnectResetRetriesWhenReconnected = false;
let autoReconnectTimeout = null;

// function getCurrentTimeStamp() {
//   var today = new Date();
//   var a = today.getFullYear();
//   var mm = today.getMonth() + 1;
//   var j = today.getDate();
//   var h = today.getHours();
//   var m = today.getMinutes();
//   var s = today.getSeconds();
//   if (mm < 10) {
//     mm = '0' + mm;
//   }
//   if (m < 10) {
//     m = '0' + m;
//   }
//   if (s < 10) {
//     s = '0' + s;
//   }
//   return '[' + a + '-' + mm + '-' + j + ' ' + h + ':' + m + ':' + s + ']';
// }

export default {
  connect(context) {
    // try {
    //   const ws = new WebSocket(process.env.VUE_APP_WSLMURL);
    //   context.commit('setIsConnected', true);
    //   context.commit('setWs', ws);
    // } catch (err) {
    //   console.log(payload);
    //   console.log(err);
    // }

    try {
      if ('WebSocket' in window) {
        // console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: WebSocket support found!');

        socketSupport = true;
        ws = new WebSocket(process.env.VUE_APP_WSLMURL);

        context.commit('setWs', ws);
      } else {
        socketSupport = false;
      }

      if (socketSupport) {
        context.state.ws.onopen = function () {
          console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Connection established!');

          context.commit('setIsConnected', true);

          if (autoReconnectResetRetriesWhenReconnected) {
            autoReconnectRetriesCounter = 1;
          }
        };

        context.state.ws.onmessage = function (e) {
          let obj = JSON.parse(e.data);

          if (typeof obj !== 'undefined') {
            if (typeof obj.ping !== 'undefined') {
              if (context.state.ws.readyState === WebSocket.OPEN) {
              let pingMS = Math.abs(Date.now() - obj.ping);
              context.state.ws.send(
                JSON.stringify({
                  command: 'pong',
                  payload: pingMS
                })
              );
              console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Received ping from server RTT: ' + pingMS + 'ms');
              }
            }
          }
        };

        context.state.ws.onerror = function () {
          console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Connection ERROR!');
          context.commit('setIsConnected', false);
        };

        context.state.ws.onclose = function () {
          console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Connection closed!');
          context.commit('setIsConnected', false);

          clearTimeout(waitTimeout);
          clearTimeout(autoReconnectTimeout);

          autoReconnectTimeout = setTimeout(function () {
            if (autoReconnect) {
              if (autoReconnectRetriesCounter <= autoReconnectNumRetries) {
                console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Reconnecting... (' + autoReconnectRetriesCounter + ' / ' + autoReconnectNumRetries + ') ');
                context.dispatch('connect');
                autoReconnectRetriesCounter++;
              } else {
                context.commit('setIsConnected', false);
                clearTimeout(waitTimeout);
                console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Reached maximum number of connection retries');
              }
            }
          }, autoReconnectInterval);
        };
      } else {
        console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Websocket not supported!');
      }
    } catch (e) {
      console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Connection ERROR! ' + e);
    }
  },
  setWs(context, payload) {
    context.commit('setWs', payload);
  },
  setIsConnected(context, payload) {
    context.commit('setIsConnected', payload);
  },
  disconnect(context) {
    clearTimeout(timer);
    timer = null;
    context.commit('disconnect');
    context.commit('setIsConnected', false);
    context.commit('setWs', null);
    console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Disconnected!');
  },
  setActiveCustomFunctions(context, payload) {
    context.commit('setActiveCustomFunctions', payload);
  },
  setCustomFunctions(context, payload) {
    context.commit('setCustomFunctions', payload);
  },
  setStatistics(context, payload) {
    context.commit('setStatistics', payload);
  },
  activateStatisticsUpdates(context, payload) {
    //console.log(context);

    context.state.ws.onmessage = ({ data }) => {
      let dataObject = JSON.parse(data);

      if (typeof dataObject !== 'undefined') {
        if (typeof dataObject.ping !== 'undefined') {
          if (context.state.ws.readyState === WebSocket.OPEN) {
            let pingMS = Math.abs(Date.now() - dataObject.ping);
            context.state.ws.send(
              JSON.stringify({
                command: 'pong',
                payload: pingMS
              })
            );
            console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Received ping from server RTT: ' + pingMS + 'ms');
          }
        }

        // if (typeof dataObject.pong !== 'undefined') {
        //   console.log(getCurrentTimeStamp() + ' StudioCast(): WSLM: Received pong from server: ' + dataObject.pong + 'ms');
        // }

        if (typeof dataObject.dashboard !== 'undefined') {
          //console.log(dataObject.dashboard);

          //
          // GLOBAL BANDWIDTH AND RESOLUTIONS UPDATE
          //
          let globalBandwidth = 0;
          let globalResolutions = {};
          for (let qualities in dataObject.dashboard.statistics.players.qualities) {
            let qNumber = parseInt(dataObject.dashboard.statistics.players.qualities[qualities]);
            let qResolution = qualities.split('@')[0];
            let qRate = parseFloat(qualities.split('@')[1].split('mbits')[0]);

            if (!isNaN(qRate)) {
              globalBandwidth += qNumber * qRate;
            }

            if (typeof qResolution !== 'undefined') {
              if (qResolution !== '') {
                if (!Object.prototype.hasOwnProperty.call(globalResolutions, qResolution)) {
                  globalResolutions[qResolution] = 0;
                }
                globalResolutions[qResolution] = globalResolutions[qResolution] + qNumber;
              }
            }
          }
          //
          //
          //

          //
          // GLOBAL PLAYERS STATUS UPDATE
          //
          let globalPlayerStatuses = {
            playing: 0,
            buffering: 0,
            erroring: 0,
            idling: 0,
            other: 0,
            total: 0
          };
          for (let playerStatus in dataObject.dashboard.statistics.players.statuses) {
            let psNumber = dataObject.dashboard.statistics.players.statuses[playerStatus];
            if (playerStatus != 'unavailable') {
              if (playerStatus == 'playing' || playerStatus == 'playingNotViewable') {
                globalPlayerStatuses.playing += psNumber;
              } else if (playerStatus == 'buffering' || playerStatus == 'bufferingNotViewable') {
                globalPlayerStatuses.buffering += psNumber;
              } else if (playerStatus == 'error' || playerStatus == 'errorNotViewable') {
                globalPlayerStatuses.erroring += psNumber;
              } else if (playerStatus == 'idle' || playerStatus == 'idleNotViewable') {
                globalPlayerStatuses.idling += psNumber;
              } else {
                globalPlayerStatuses.other += psNumber;
              }
              globalPlayerStatuses.total += psNumber;
            }
          }
          //
          //
          //

          //
          // GLOBAL OPERATING SYSTEMS UPDATE
          //
          let globalOs = {
            windows: 0,
            macos: 0,
            ios: 0,
            android: 0,
            other: 0
          };
          for (let os in dataObject.dashboard.statistics.oses) {
            let osNumber = dataObject.dashboard.statistics.oses[os];
            if (os.toLowerCase().includes('windows')) {
              globalOs.windows += osNumber;
            } else if (os.toLowerCase().includes('mac')) {
              globalOs.macos += osNumber;
            } else if (os.toLowerCase().includes('ios')) {
              globalOs.ios += osNumber;
            } else if (os.toLowerCase().includes('android')) {
              globalOs.android += osNumber;
            } else {
              globalOs.other += osNumber;
            }
          }
          //
          //
          //

          //
          // GLOBAL VIEWERS UPDATE
          //
          let globalViewers = {
            prelive: 0,
            live: 0,
            tempstop: 0,
            noarchive: 0,
            prearchive: 0,
            archive: 0,
            complete: 0,
            unknown: 0,
            dashboard: 0,
            watching: 0,
            buffering: 0,
            errors: 0,
            total: 0
          };
          globalViewers.prelive = dataObject.dashboard.statistics.viewers.prelive + dataObject.dashboard.statistics.viewers.availableSoon;
          globalViewers.live = dataObject.dashboard.statistics.viewers.live;
          globalViewers.tempstop = dataObject.dashboard.statistics.viewers.temporarelyStopped + dataObject.dashboard.statistics.viewers.tempstop;
          globalViewers.noarchive = dataObject.dashboard.statistics.viewers.noarchive + dataObject.dashboard.statistics.viewers.unavailable;
          globalViewers.prearchive = dataObject.dashboard.statistics.viewers.prearchive;
          globalViewers.archive = dataObject.dashboard.statistics.viewers.archive;
          globalViewers.complete = dataObject.dashboard.statistics.viewers.complete;
          globalViewers.unknown = dataObject.dashboard.statistics.viewers.unknown;
          globalViewers.dashboard = dataObject.dashboard.statistics.viewers.dashboard;
          globalViewers.watching = dataObject.dashboard.statistics.viewers.watching;
          globalViewers.buffering = dataObject.dashboard.statistics.viewers.buffering;
          globalViewers.errors = dataObject.dashboard.statistics.viewers.errors;
          globalViewers.total = dataObject.dashboard.statistics.viewers.total;
          //console.log(dataObject.dashboard.statistics);
          //
          //
          //

          // console.log(globalBandwidth);
          // console.log(globalResolutions);
          // console.log(globalPlayerStatuses);
          dataObject.dashboard.computedStatistics = {
            globalBandwidth: globalBandwidth,
            globalResolutions: globalResolutions,
            globalPlayerStatuses: globalPlayerStatuses,
            globalOs: globalOs,
            globalViewers: globalViewers
          };

          context.commit('setStatistics', dataObject.dashboard);
          context.commit('setCustomFunctions', dataObject.dashboard.statistics.customFunctions);
        }

        if (typeof dataObject.activecustomfunctions !== 'undefined') {
          context.commit('setActiveCustomFunctions', dataObject.activecustomfunctions);
        }
      }
    };

    timer = setInterval(function () {
      if (context.state.ws.readyState === WebSocket.OPEN) {
        context.state.ws.send(
          JSON.stringify({
            command: 'dashboard.subscribe',
            payload: payload
          })
        );
      }
    }, 2000);
  },
  deactivateStatisticsUpdates() {
    // console.log('KILLING DASHBOARD TIMER!');
    // console.log(timer);
    clearInterval(timer);
    timer = null;
    // console.log('After');
    // console.log(timer);
  }
};
