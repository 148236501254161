export default {
  getEnvironment(state) {
    return state.environment;
  },
  getTable(state) {
    return state.table;
  },
  getTableRows(state) {
    return state.table.data;
  },
  getNow(state) {
    return state.now;
  },
  getNowSC(state) {
    return state.nowSC;
  },
  getRoute(state) {
    return state.route;
  },
  getLogoLarge(state) {
    return state.logo.large;
  },
  getLogoSmall(state) {
    return state.logo.small;
  },
  getAvailableLanguages(state) {
    return state.availableLanguages;
  },
  getLanguage(state) {
    return state.language;
  },
  getLanguageAcronym(state) {
    return state.language.language;
  },
  getLanguageName(state) {
    return state.language.name;
  },
  getLanguageFlag(state) {
    return state.language.flag;
  }
};
