<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {},
  methods: {},
  computed: {},
  created() {
    if (localStorage.getItem('sc-dashboard-mode')) {
      const mode = localStorage.getItem('sc-dashboard-mode');
      document.documentElement.setAttribute('data-layout-mode', mode);
      this.$store.dispatch('layout/changeMode', {
        mode: mode
      });
    }
    this.$store.dispatch('setNowSC');
    this.$store.dispatch('setTranslator', { translator: this.$t });
    this.$store.dispatch('autoSetLanguage');
    this.$store.dispatch('authentication/autoLogin');
    this.$store.dispatch('startTicker');
  },
  mounted() {
    //console.log(this);
  },
  watch: {}
};
</script>
