export default {
  setIsConnected(state, payload) {
    state.isConnected = payload;
  },
  setWs(state, payload) {
    // console.log('state');
    // console.log(state);

    // console.log('payload');
    // console.log(payload);

    state.ws = payload;
  },
  // disconnect(state) {
  //   state.ws.disconnect();
  // }
};
