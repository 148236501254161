import baseActions from '@/store/base/actions.js';
import baseGetters from '@/store/base/getters.js';
import baseMutations from '@/store/base/mutations.js';

export default {
  namespaced: true,
  state() {
    return {};
  },
  actions: baseActions,
  getters: baseGetters,
  mutations: baseMutations
};
