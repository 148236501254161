import profileActions from '@/store/profile/actions.js';
import profileGetters from '@/store/profile/getters.js';
import profileMutations from '@/store/profile/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: [],
      logoUploadPercentage: 0
    };
  },
  actions: profileActions,
  getters: profileGetters,
  mutations: profileMutations
};
