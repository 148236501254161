export default {
  resetMessages(state) {
    state.messages = {
      organizations: {},
      divisions: {},
      clients: {},
      events: {},
      breakouts: {}
    };
    //window.sessionStorage.removeItem('vuex');
    //window.sessionStorage.clear();
  },
  addMessage(state, payload) {
    //TODO: Limit the size of the messages arrays to X
    if (payload.to.type === 'organization') {
      if (typeof state.messages.organizations[payload.to.name] === 'undefined') {
        state.messages.organizations[payload.to.name] = [];
      }
      state.messages.organizations[payload.to.name].push(payload);
    } else if (payload.to.type === 'client') {
      if (typeof state.messages.clients[payload.to.name] === 'undefined') {
        state.messages.clients[payload.to.name] = [];
      }
      state.messages.clients[payload.to.name].push(payload);
    } else if (payload.to.type === 'division') {
      if (typeof state.messages.divisions[payload.to.name] === 'undefined') {
        state.messages.divisions[payload.to.name] = [];
      }
      state.messages.divisions[payload.to.name].push(payload);
    } else if (payload.to.type === 'event') {
      if (typeof state.messages.events[payload.to.name] === 'undefined') {
        state.messages.events[payload.to.name] = [];
      }
      state.messages.events[payload.to.name].push(payload);
    } else if (payload.to.type === 'breakout') {
      if (typeof state.messages.breakouts[payload.to.name] === 'undefined') {
        state.messages.breakouts[payload.to.name] = [];
      }
      state.messages.breakouts[payload.to.name].push(payload);
    }
  },
  setMessages(state, payload) {
    //console.log('setMessages');
    //console.log(payload);

    for (let index = 0; index < payload.length; ++index) {
      // console.log("CHECKING " + index);
      // console.log(payload[index]);

      if (payload[index].to.type === 'organization') {
        if (typeof state.messages.organizations[payload[index].to.name] === 'undefined') {
          state.messages.organizations[payload[index].to.name] = [];
        }
        state.messages.organizations[payload[index].to.name].push({
          to: payload[index].to,
          from: payload[index].from,
          message: payload[index].message,
          time: payload[index].created_at
        });
      } else if (payload[index].to.type === 'client') {
        if (typeof state.messages.clients[payload[index].to.name] === 'undefined') {
          state.messages.clients[payload[index].to.name] = [];
        }
        state.messages.clients[payload[index].to.name].push({
          to: payload[index].to,
          from: payload[index].from,
          message: payload[index].message,
          time: payload[index].created_at
        });
      } else if (payload[index].to.type === 'division') {
        if (typeof state.messages.divisions[payload[index].to.name] === 'undefined') {
          state.messages.divisions[payload[index].to.name] = [];
        }
        state.messages.divisions[payload[index].to.name].push({
          to: payload[index].to,
          from: payload[index].from,
          message: payload[index].message,
          time: payload[index].created_at
        });
      } else if (payload[index].to.type === 'event') {
        if (typeof state.messages.events[payload[index].to.name] === 'undefined') {
          state.messages.events[payload[index].to.name] = [];
        }
        state.messages.events[payload[index].to.name].push({
          to: payload[index].to,
          from: payload[index].from,
          message: payload[index].message,
          time: payload[index].created_at
        });
      } else if (payload[index].to.type === 'breakout') {
        if (typeof state.messages.breakouts[payload[index].to.name] === 'undefined') {
          state.messages.breakouts[payload[index].to.name] = [];
        }
        state.messages.breakouts[payload[index].to.name].push({
          to: payload[index].to,
          from: payload[index].from,
          message: payload[index].message,
          time: payload[index].created_at
        });
      }
    }
  },
  setRoom(state, payload) {
    state.room = payload;
  },
  setRooms(state, payload) {
    state.rooms = payload;
  },
  setUsers(state, payload) {
    // console.log('setusers!');
    // console.log(payload);
    state.users = payload;
  }
};
