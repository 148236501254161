import schedulerActions from '@/store/scheduler/actions.js';
import schedulerGetters from '@/store/scheduler/getters.js';
import schedulerMutations from '@/store/scheduler/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: []
    };
  },
  actions: schedulerActions,
  getters: schedulerGetters,
  mutations: schedulerMutations
};
