import organizationsActions from '@/store/organizations/actions.js';
import organizationsGetters from '@/store/organizations/getters.js';
import organizationsMutations from '@/store/organizations/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: [],
      logoUploadPercentage: 0
    };
  },
  actions: organizationsActions,
  getters: organizationsGetters,
  mutations: organizationsMutations
};
