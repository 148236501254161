import chatActions from '@/store/chat/actions.js';
import chatGetters from '@/store/chat/getters.js';
import chatMutations from '@/store/chat/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      room: {
        id: '',
        uuid: '',
        type: '',
        name: '',
        avatar: require('@/assets/images/users/user-dummy-img.jpg')
      },
      messages: {
        organizations: {},
        divisions: {},
        clients: {},
        events: {},
        breakouts: {}
      },
      rooms: {
        organizations: [],
        divisions: [],
        clients: [],
        events: [],
        breakouts: []
      },
      users: []
    };
  },
  actions: chatActions,
  getters: chatGetters,
  mutations: chatMutations
};
