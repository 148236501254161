import notificationsActions from '@/store/notifications/actions.js';
import notificationsGetters from '@/store/notifications/getters.js';
import notificationsMutations from '@/store/notifications/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      all: [],
      alerts: [],
      messages: []
    };
  },
  actions: notificationsActions,
  getters: notificationsGetters,
  mutations: notificationsMutations
};
