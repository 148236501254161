import subscriptionsActions from '@/store/subscriptions/actions.js';
import subscriptionsGetters from '@/store/subscriptions/getters.js';
import subscriptionsMutations from '@/store/subscriptions/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: []
    };
  },
  actions: subscriptionsActions,
  getters: subscriptionsGetters,
  mutations: subscriptionsMutations
};
