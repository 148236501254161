const getCurrentTimeStamp = () => {
  var today = new Date();
  var a = today.getFullYear();
  var mm = today.getMonth() + 1;
  var j = today.getDate();
  var h = today.getHours();
  var m = today.getMinutes();
  var s = today.getSeconds();
  if (j < 10) {
    j = '0' + j;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (m < 10) {
    m = '0' + m;
  }
  if (s < 10) {
    s = '0' + s;
  }
  return '[' + a + '-' + mm + '-' + j + ' ' + h + ':' + m + ':' + s + ']';
}

module.exports = {
  getCurrentTimeStamp: getCurrentTimeStamp
};