import reportsActions from '@/store/reports/actions.js';
import reportsGetters from '@/store/reports/getters.js';
import reportsMutations from '@/store/reports/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
    };
  },
  actions: reportsActions,
  getters: reportsGetters,
  mutations: reportsMutations
};
