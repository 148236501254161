import { getCurrentTimeStamp } from '@/common';
import io from 'socket.io-client';

let socket;

export default {
  connect(context) {
    try {
      socket = io(process.env.VUE_APP_EMAURL, {
        transports: ['websocket'],
        query: {
          token: context.rootState.authentication.userToken
        }
      });

      socket.on('connect', () => {
        // console.log('CONNECTED TO PRIVATE SERVER');
        console.log(getCurrentTimeStamp() + ' StudioCast(): EMAWS: Connection established!');
        context.commit('setIsConnected', true);
        context.commit('setWs', socket);
        socket.emit('SET_USER', {
          fullname: context.rootState.authentication.userDetails.data.fullname,
          avatar: context.rootState.authentication.userAvatar.small,
          location: context.rootState.route.to.path,
          locationElement: null
        });
      });

      socket.on('disconnect', (reason) => {
        // console.log('DISCONNECTED');
        console.log(getCurrentTimeStamp() + ' StudioCast(): EMAWS: Connection closed!');
        console.log(reason);

        // console.log(socket.id); // undefined
        // context.commit('setIsConnected', false);
        // context.commit('setWs', null);
        // context.commit('chat/setUsers', [], {
        //   root: true
        // });
        // context.commit('chat/setRooms', [], {
        //   root: true
        // });
      });

      socket.on('UPDATE', (data) => {
        console.log('UPDATE RECEIVED');
        console.log(data);

        // console.log(typeof data.users);
        // console.log(data.users);

        if (typeof data.users !== 'undefined') {
          context.commit('chat/setUsers', data.users, {
            root: true
          });
        }

        if (typeof data.rooms !== 'undefined') {
          context.commit('chat/setRooms', data.rooms, {
            root: true
          });
        }
      });

      socket.on('NEW_MESSAGE', (data) => {
        //console.log('NEW_MESSAGE RECEIVED');
        // console.log(data);
        context.commit('chat/addMessage', data, {
          root: true
        });
      });

      // socket.on('LIST_USERS', (users) => {
      //   // console.log('LIST_USERS RECEIVED');
      //   // console.log(users);
      //   context.commit('setChatUsers', users);
      // });

      // socket.on('LIST_ROOMS', (rooms) => {
      //   // console.log('LIST_ROOMS RECEIVED');
      //   // console.log(rooms);
      //   context.commit('setChatRooms', rooms);
      // });

      // socket.on('MESSAGE', (data) => {
      //   // console.log('CONNECTED TO PRIVATE SERVER');
      //   console.log(data);
      // });

      socket.on('ALERT', (data) => {
        console.log('RECEIVED MESSAGE FROM SERVER');
        // console.log('CONNECTED TO PRIVATE SERVER');
        // console.log(socket.id); // x8WIv7-mJelg7on_ALbx
        console.log(data);
        // console.log(payload);
        // console.log(context.rootState.translator('t-notification-' + data.name));
        //console.log(context);
        context.commit('notifications/setNotification', data, {
          root: true
        });
        context.commit('addTableRow', data.entry, {
          root: true
        });
        context.dispatch(
          'notifications/displayNotification',
          {
            type: 'success',
            message: context.rootState.translator('t-notification-' + data.notification.name),
            response: {}
          },
          {
            root: true
          }
        );
      });

      socket.on('DASHBOARD_UPDATE', (data) => {
        //console.log('DASHBOARD_UPDATE RECEIVED');
        // context.commit('updateDashboard', data, {
        //   root: true
        // });
        // console.log(data);
        context.dispatch(
          'notifications/displayDashboardUpdateAlert',
          {
            title: context.rootState.translator('t-notification-dashboard-update-title'),
            message: context.rootState.translator('t-notification-dashboard-update-message'),
            data: data
          },
          {
            root: true
          }
        );
      });

      socket.on('TIME_SYNC', () => {
        // console.log('TIME_SYNC RECEIVED');
        context.dispatch('setNowSC', {}, { root: true });
      });
      
    } catch (err) {
      console.log(err);
    }
  },
  disconnect(context) {
    socket.disconnect();
    //context.commit('disconnect2');
    context.commit('setIsConnected', false);
    context.commit('setWs', null);

    context.commit(
      'notifications/resetNotifications',
      {},
      {
        root: true
      }
    );
    context.commit(
      'chat/resetMessages',
      {},
      {
        root: true
      }
    );

    context.commit('chat/setUsers', [], {
      root: true
    });
    context.commit('chat/setRooms', [], {
      root: true
    });

    console.log(getCurrentTimeStamp() + ' StudioCast(): EMAWS: Disconnected!');
  },
  sendMessage(context, payload) {
    try {
      socket.emit('NEW_MESSAGE', payload);
    } catch (err) {
      console.log(err);
    }
  },
  sendLocation(context, payload) {
    try {
      // console.log('EMITTING NEW LOCATION');
      // console.log(payload);
      if (socket !== undefined) {
        socket.emit('SET_USER_LOCATION', payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  sendLocationElement(context, payload) {
    try {
      // console.log('EMITTING NEW LOCATION ELEMENT');
      // console.log(payload.srcElement.id);
      socket.emit('SET_USER_LOCATION_ELEMENT', payload.srcElement.id);
    } catch (err) {
      console.log(err);
    }
  },
  async sendDashboardUpdate(context, payload) {
    try {
      // console.log('EMITTING DASHBOARD_UPDATE');
      // console.log(payload);
      await socket.emit('DASHBOARD_UPDATE', payload);
    } catch (err) {
      console.log(err);
    }
  }
  // getUsersList() {
  //   //console.log(context);
  //   //context.commit('GET_USERS', payload);
  //   socket.emit('LIST_USERS');
  // },
  // getRoomsList() {
  //   //console.log(context);
  //   //context.commit('GET_ROOMS', payload);
  //   socket.emit('LIST_ROOMS');
  // }
};
