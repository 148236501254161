import clientsActions from '@/store/clients/actions.js';
import clientsGetters from '@/store/clients/getters.js';
import clientsMutations from '@/store/clients/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: [],
      logoUploadPercentage: 0
    };
  },
  actions: clientsActions,
  getters: clientsGetters,
  mutations: clientsMutations
};
