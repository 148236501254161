import axios from 'axios';

export default {
  async getMessages(context, payload) {
    // console.log(context);
    //console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/messages');

    if (typeof payload.page !== 'undefined') {
      fetchUrl.searchParams.set('_page', payload.page);
    }

    if (typeof payload.limit !== 'undefined') {
      fetchUrl.searchParams.set('_limit', payload.limit);
    }

    if (typeof payload.offset !== 'undefined') {
      fetchUrl.searchParams.set('_offset', payload.offset);
    }

    if (typeof payload.sort !== 'undefined') {
      let sortString = '';
      Object.keys(payload.sort).forEach(function (item) {
        if (payload.sort[item].active) {
          sortString += item + ':' + payload.sort[item].order + ',';
        }
      });

      if (sortString !== '') {
        fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
      }
    }

    if (typeof payload.filters !== 'undefined') {
      let filtersString = '';
      payload.filters.forEach((element) => {
        if (element.name === 'state' || element.name === 'locked' || element.name === 'deleted') {
          if (element.value === true) {
            filtersString += element.name + ':1,';
          } else {
            filtersString += element.name + ':0,';
          }
        } else {
          filtersString += element.name + ':' + element.value + ',';
        }
      });

      if (filtersString !== '') {
        fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
      } else {
        fetchUrl.searchParams.set('_filter','state:1;0,locked:1;0,deleted:1;0');
      }
    }

    //console.log(fetchUrl.href);

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    if (payload.updateStore) {
      context.commit('resetMessages');
      context.commit('setMessages', response.data.result);
    }

    return response;
  }
};
