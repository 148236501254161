export default {
  changeLayoutType(context, { layoutType }) {
    context.commit('CHANGE_LAYOUT', layoutType);
    document.body.removeAttribute('style');
  },

  changeLayoutWidth(context, { layoutWidth }) {
    context.commit('CHANGE_LAYOUT_WIDTH', layoutWidth);
  },

  changeSidebarSize(context, { sidebarSize }) {
    context.commit('CHANGE_SIDEBAR_TYPE', sidebarSize);
  },

  changeTopbar(context, { topbar }) {
    context.commit('CHANGE_TOPBAR', topbar);
  },

  changeMode(context, { mode }) {
    context.commit('CHANGE_MODE', mode);
    context.commit(
      'setLogo',
      {},
      {
        root: true
      }
    );
  },

  changePosition(context, { position }) {
    context.commit('CHANGE_POSITION', position);
  },

  changeSidebarView(context, { sidebarView }) {
    context.commit('CHANGE_SIDEBAR_VIEW', sidebarView);
  },

  changeSidebarColor(context, { sidebarColor }) {
    context.commit('CHANGE_SIDEBAR_COLOR', sidebarColor);
  }
};
