import authenticationActions from '@/store/authentication/actions.js';
import authenticationGetters from '@/store/authentication/getters.js';
import authenticationMutations from '@/store/authentication/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      userAvatar: {
        original: require('@/assets/images/logos/pqm_dark_128.png'),
        large: require('@/assets/images/logos/pqm_dark_128.png'),
        small: require('@/assets/images/logos/studiocast_light_80.png'),
        icon: require('@/assets/images/logos/studiocast_light_80.png')
      },
      userLoggedIn: false,
      userName: null,
      userPassword: null,
      userDetails: {},
      userToken: null,
      userTokenExpiration: null
    };
  },
  actions: authenticationActions,
  getters: authenticationGetters,
  mutations: authenticationMutations
};
