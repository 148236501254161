import i18n from '@/i18n';

// let timerUpdate = 30;
export default {
  refreshTicker(state) {
    state.now = new Date();
    state.nowSC = new Date(state.nowSC.setSeconds(state.nowSC.getSeconds() + 1));
    //console.log("REFRESHING TICKER");
    // console.log(state.now);
    // console.log(state.nowSC);

    // if (state.updateDashboard) {
    //   //console.log("UPDATE DASHBOARD HAS BEEN CALLED!!");

    //   timerUpdate--;

    //   console.log("Updating dashboard in " + timerUpdate);

    //   if (timerUpdate == 0) {

    //     state.updateDashboard = false;
    //     timerUpdate = 30;

    //     // CALL ACTION

    //   }

    // }
  },
  setNowSC(state, payload) {
    state.nowSC = new Date(payload.date);
  },
  // updateDashboard(state, payload) {
  //   state.updateDashboard = payload.update;
  //   console.log("DASHBOARD UPDATE: " + payload.update);
  //   console.log(payload);
  // },
  setRoute(state, payload) {
    // console.log('state setRoute');
    // console.log(state);
    // console.log('payload setRoute');
    // console.log(payload);

    state.route = payload;
  },
  resetTable(state) {
    state.table = {
      data: [],
      totalPages: 1,
      currentPage: 1,
      previousPage: 1,
      nextPage: 2,
      totalItems: 0
    };
  },
  setTable(state, payload) {
    state.table = payload;
  },
  addTableRow(state, payload) {
    state.table.data.unshift(payload);
    // TODO: adjust this for the user table setting for limit per page
    if (state.table.totalItems >= 50) {
      state.table.data.pop();
    }
    state.table.totalItems++;
  },
  // setTableRow(state, payload) {
  //   state.table.data = payload;
  // },
  setTableRows(state, payload) {
    state.table.data = payload;
  },
  setTableTotalItems(state, payload) {
    state.table.totalItems = payload;
  },
  setTranslator(state, payload) {
    state.translator = payload.translator;
  },
  setLanguage(state, payload) {
    state.language = {
      language: payload.language,
      name: payload.name,
      flag: payload.flag
    };
    i18n.global.locale = payload.language;
    localStorage.setItem('sc-dashboard-language', payload.language);
    if (state.layout.mode === 'dark') {
      if (payload.language === 'en') {
        state.logo = {
          large: require('@/assets/images/logos/studiocast_dark_128_en.png'),
          small: require('@/assets/images/logos/studiocast_dark_80.png')
        };
      } else {
        state.logo = {
          large: require('@/assets/images/logos/studiocast_dark_128_fr.png'),
          small: require('@/assets/images/logos/studiocast_dark_80.png')
        };
      }
    } else {
      if (payload.language === 'en') {
        state.logo = {
          large: require('@/assets/images/logos/studiocast_light_128_en.png'),
          small: require('@/assets/images/logos/studiocast_light_80.png')
        };
      } else {
        state.logo = {
          large: require('@/assets/images/logos/studiocast_light_128_fr.png'),
          small: require('@/assets/images/logos/studiocast_light_80.png')
        };
      }
    }
  },
  setLogo(state) {
    if (state.layout.mode === 'dark') {
      if (state.language.language === 'en') {
        state.logo = {
          large: require('@/assets/images/logos/studiocast_dark_128_en.png'),
          small: require('@/assets/images/logos/studiocast_dark_80.png')
        };
      } else {
        state.logo = {
          large: require('@/assets/images/logos/studiocast_dark_128_fr.png'),
          small: require('@/assets/images/logos/studiocast_dark_80.png')
        };
      }
    } else {
      if (state.language.language === 'en') {
        state.logo = {
          large: require('@/assets/images/logos/studiocast_light_128_en.png'),
          small: require('@/assets/images/logos/studiocast_light_80.png')
        };
      } else {
        state.logo = {
          large: require('@/assets/images/logos/studiocast_light_128_fr.png'),
          small: require('@/assets/images/logos/studiocast_light_80.png')
        };
      }
    }
  },
  rowCommentTeleprompterUpdating(state, payload) {
    payload.isDisplayStatusUpdating = true;
  },
  rowCommentTeleprompterUpdated(state, payload) {
    payload.teleprompter_show = !payload.teleprompter_show;
    payload.isDisplayStatusUpdating = false;
  },
  rowCommentAnsweredUpdating(state, payload) {
    payload.isReadStatusUpdating = true;
  },
  rowCommentAnsweredUpdated(state, payload) {
    payload.teleprompter_answered = !payload.teleprompter_answered;
    payload.isReadStatusUpdating = false;
  },
  rowCommentTextUpdate(state, payload) {
    // payload.teleprompter_answered = !payload.teleprompter_answered;
    // console.log(payload);
    payload.comment.comment = payload.text;
  }
};
