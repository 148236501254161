export default {
  setIsConnected(state, payload) {
    state.isConnected = payload;
  },
  setWs(state, payload) {
    state.ws = payload;
  },
  disconnect(state) {
    state.ws.close();
  },
  setActiveCustomFunctions(state, payload) {
    state.activeCustomFunctions = payload;
  },
  setCustomFunctions(state, payload) {
    state.customFunctions = payload;
  },
  setStatistics(state, payload) {
    state.statistics = payload;
  }
};
