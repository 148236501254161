import axios from 'axios';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification';

export default {
  markAsRead(context, payload) {
    context.commit('markAsRead', payload);
    context.dispatch('updateNotification', { uuid: payload.uuid, read: 1 });
  },
  setNotification(context, payload) {
    context.commit('setNotification', payload);
  },
  async getNotifications(context, payload) {
    // console.log(context);
    //console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/notifications');

    if (typeof payload.page !== 'undefined') {
      fetchUrl.searchParams.set('_page', payload.page);
    }

    if (typeof payload.limit !== 'undefined') {
      fetchUrl.searchParams.set('_limit', payload.limit);
    }

    if (typeof payload.offset !== 'undefined') {
      fetchUrl.searchParams.set('_offset', payload.offset);
    }

    if (typeof payload.sort !== 'undefined') {
      let sortString = '';
      Object.keys(payload.sort).forEach(function (item) {
        if (payload.sort[item].active) {
          sortString += item + ':' + payload.sort[item].order + ',';
        }
      });

      if (sortString !== '') {
        fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
      }
    }

    if (typeof payload.filters !== 'undefined') {
      let filtersString = '';
      payload.filters.forEach((element) => {
        if (element.name === 'state' || element.name === 'locked' || element.name === 'deleted') {
          if (element.value === true) {
            filtersString += element.name + ':1,';
          } else {
            filtersString += element.name + ':0,';
          }
        } else {
          filtersString += element.name + ':' + element.value + ',';
        }
      });

      if (filtersString !== '') {
        fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
      } else {
        fetchUrl.searchParams.set('_filter','state:1;0,locked:1;0,deleted:1;0');
      }
    }

    //console.log(fetchUrl.href);

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    if (payload.updateStore) {
      context.commit('resetNotifications');
      context.commit('setNotifications', response.data.result);
    }

    return response;
  },
  async deleteNotification(context, payload) {
    const userResponse = await Swal.fire({
      title: payload.i18n('t-notifications-delete-areyousure'),
      text: payload.i18n('t-notifications-delete-confirm'),
      showCancelButton: true,
      confirmButtonColor: '#1367c8',
      confirmButtonText: payload.i18n('t-yes'),
      cancelButtonColor: '#0ab39c',
      cancelButtonText: payload.i18n('t-cancel')
    });
    if (userResponse.isConfirmed) {
      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/notifications/' + payload.uuid);
      const config = {
        method: 'delete',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };
      const response = await axios(config);
      return response;
    } else {
      return false;
    }
  },
  async getNotificationByUuid(context, payload) {
    //console.log(context);
    //console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/notifications/' + payload.uuid);

    //console.log(fetchUrl.href);

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);

    return response;
  },
  async updateNotification(context, payload) {
    //console.log(context);
    //console.log(payload);

    if (typeof payload.state !== 'undefined') {
      if (payload.state === true) {
        payload.state = 1;
      } else {
        payload.state = 0;
      }
    }

    if (typeof payload.locked !== 'undefined') {
      if (payload.locked === true) {
        payload.locked = 1;
      } else {
        payload.locked = 0;
      }
    }

    if (typeof payload.deleted !== 'undefined') {
      if (payload.deleted === true) {
        payload.deleted = 1;
      } else {
        payload.deleted = 0;
      }
    }

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/notifications/' + payload.uuid);

    const config = {
      method: 'put',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      },
      data: payload
    };

    const response = await axios(config);

    return response;
  },
  displayNotification(context, payload) {
    console.log(context);
    console.log(payload);
    const toast = useToast();
    toast(payload.message, {
      type: payload.type,
      timeout: 8000,
      position: 'top-right',
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: 'button',
      icon: true,
      rtl: false
    });
  },
  displayNotificationBackup(context, payload) {
    // console.log(context);
    // console.log(payload);

    let timerActive = false;
    let timerDuration = 30000;

    if (payload.type === 'success') {
      timerActive = true;
      timerDuration = 8000;
    }

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: timerDuration,
      timerProgressBar: timerActive,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
    Toast.fire({
      showClass: {
        popup: 'animated fadeInDown faster',
        backdrop: 'swal2-backdrop-show',
        icon: 'animated heartBeat delay-1s'
      },
      hideClass: {
        popup: 'animated fadeOutUp faster',
        backdrop: 'swal2-backdrop-hide',
        icon: 'swal2-icon-hide'
      },
      icon: payload.type,
      title: payload.message,
      confirmButtonColor: '#4783E6'
    });

    payload.from = 'System';
    payload.id = 0;
    //context.dispatch('setNotification', payload);
  },
  displayAlert(context, payload) {
    // console.log(context);
    // console.log(payload);

    // let timerActive = false;
    // let timerDuration = 30000;
    let footer = null;

    if (payload.type === 'error' && typeof payload.response !== 'undefined' && typeof payload.response.data.studiocast.requestId !== 'undefined') {
      footer = '<a href="/support/request/' + payload.response.data.studiocast.requestId + '">Report this issue</a>';
    }

    Swal.fire({
      title: payload.title,
      icon: payload.type,
      confirmButtonColor: '#1367c8',
      text: payload.message,
      footer: footer
    });

    payload.from = 'System';
    payload.id = 0;
    //context.dispatch('setNotification', payload);
  },
  displayDashboardUpdateAlert(context, payload) {
    // console.log(context);
    // console.log(payload);

    if (payload.data.update) {
      // let timerInterval;
      Swal.fire({
        title: payload.title,
        html: payload.message,
        timer: 30000,
        timerProgressBar: true,
        icon: 'warning',
        footer: null,
        // position: 'center-start',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false
        // loaderHtml: '<div class="mt-4 text-muted" class="rotate"><i class="ri-refresh-line fs-1"></i></div>',
        // didOpen: () => {
        //   //Swal.showLoading();
        //   // const div = Swal.getHtmlContainer().querySelector('div');

        //   // div.innerHTML = '<i class="ri-refresh-line fs-1"></i>';

        //   // timerInterval = setInterval(() => {
        //   //   b.textContent = Swal.getTimerLeft()
        //   // }, 100)
        // },
        // willClose: () => {
        //   clearInterval(timerInterval);
        // }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          // console.log('I was closed by the timer');
          window.location.reload();
        }
      });
    }
  }
  // setNotification(context, payload) {
  //   payload.date = new Date();
  //   context.commit('setNotification', payload);
  // }
};
