import commentsActions from '@/store/comments/actions.js';
import commentsGetters from '@/store/comments/getters.js';
import commentsMutations from '@/store/comments/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      teleprompter: {
        showing: [],
        answered: []
      }
    };
  },
  actions: commentsActions,
  getters: commentsGetters,
  mutations: commentsMutations
};
