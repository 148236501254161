import breakoutsActions from '@/store/breakouts/actions.js';
import breakoutsGetters from '@/store/breakouts/getters.js';
import breakoutsMutations from '@/store/breakouts/mutations.js';

export default {
  namespaced: true,
  state() {
    return {
      data: [],
      logoUploadPercentage: 0
    };
  },
  actions: breakoutsActions,
  getters: breakoutsGetters,
  mutations: breakoutsMutations
};
