export default {
  getIsConnected(state) {
    //console.log(typeof state.ws);
    //console.log(typeof state.ws.connected);
    // if (typeof state.ws !== 'undefined') {
    //   return false;
    // } else {
    //   return false;
    // }
    //return state.ws.connected || false;
    //console.log(state);
    //return false;
    return state.isConnected;
  },
  getWs(state) {
    return state.ws;
  }
};
